import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';

import { useTopNavigationLinks } from '../../../hooks/useTopNavigationLinks';

const MobileMenuNavSection = () => {
  const { navigation } = useTopNavigationLinks();

  return (
    <>
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.to}
          className={clsx(
            item.isActive ? 'bg-gray-100' : 'hover:bg-gray-100',
            'block rounded-md py-2 px-3 font-medium text-gray-900',
          )}
          aria-current={item.isActive ? 'page' : undefined}
        >
          {item.name}
        </Link>
      ))}
    </>
  );
};

export { MobileMenuNavSection };
