import React from 'react';

const SocialIcon = ({ item }) => (
  <a href={item.href} className="text-gray-400 hover:text-gray-500" target="_blank" rel="noreferrer">
    <span className="sr-only">{item.name}</span>
    <item.icon className="h-6 w-6" aria-hidden="true" />
  </a>
);

export { SocialIcon };
