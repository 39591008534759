import React from 'react';

import { Copyright } from './Copyright';
import { SocialNavigation } from './SocialNavigation';

const Footer = () => {
  return (
    <footer className="bg-gray-50" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto pb-8 px-4 lg:px-8">
        <div className="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
          <SocialNavigation />
          <Copyright />
        </div>
      </div>
    </footer>
  );
};

export { Footer };
