import React from 'react';

import { LinkedIn } from '../Icons';
import { SocialIcon } from '../SocialIcon';

const navigationItems = [
  // {
  //   name: 'Facebook',
  //   href: '#',
  //   icon: Facebook,
  // },
  // {
  //   name: 'Instagram',
  //   href: '#',
  //   icon: Instagram,
  // },
  // {
  //   name: 'Twitter',
  //   href: '#',
  //   icon: Twitter,
  // },
  // {
  //   name: 'GitHub',
  //   href: 'https://github.com/skyintio/',
  //   icon: GitHub,
  // },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/skyintio',
    icon: LinkedIn,
  },
];

const SocialNavigation = () => (
  <div className="flex space-x-6 md:order-2">
    {navigationItems.map((item) => (
      <SocialIcon key={item.name} item={item} />
    ))}
  </div>
);

export { SocialNavigation };
