import { useLocation } from '@reach/router';
import { useMemo } from 'react';

import defaultNavigation from '../data/topNavigation.json';

const useTopNavigationLinks = () => {
  const { pathname } = useLocation();

  const navigation = useMemo(
    () => defaultNavigation.map((item) => ({ ...item, isActive: pathname === item.to })),
    [pathname],
  );

  return { navigation };
};

export { useTopNavigationLinks };
