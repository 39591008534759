import { Link } from 'gatsby';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const NavbarLogo = () => (
  <>
    <div className="flex-shrink-0 hidden md:block">
      <Link to="/">
        <StaticImage
          placeholder="none"
          layout="fixed"
          src="../../../assets/skyint_logo_large.png"
          alt="Skyint Company Logo"
          width={147}
          height={32}
        />
      </Link>
    </div>
    <div className="flex-shrink-0 md:hidden lg:hidden">
      <Link to="/">
      <StaticImage
          placeholder="none"
          layout="fixed"
          src="../../../assets/skyint_logo_large.png"
          alt="Skyint Company Logo"
          width={147}
          height={32}
        />
      </Link>
    </div>
  </>
);

export { NavbarLogo };
