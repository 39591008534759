// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-description-tsx": () => import("./../../../src/pages/job-description.tsx" /* webpackChunkName: "component---src-pages-job-description-tsx" */),
  "component---src-pages-our-values-tsx": () => import("./../../../src/pages/our-values.tsx" /* webpackChunkName: "component---src-pages-our-values-tsx" */),
  "component---src-pages-recruitment-process-tsx": () => import("./../../../src/pages/recruitment-process.tsx" /* webpackChunkName: "component---src-pages-recruitment-process-tsx" */)
}

