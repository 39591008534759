import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';

import { useTopNavigationLinks } from '../../../hooks/useTopNavigationLinks';

const LinkSection = () => {
  const { navigation } = useTopNavigationLinks();

  return (
    <>
      {navigation.map((item) => (
        <Link
          key={item.name}
          to={item.to}
          className={clsx(
            item.isActive ? 'bg-gray-100' : 'hover:text-gray-700',
            'px-3 py-2 rounded-md text-sm font-medium text-gray-900',
          )}
          aria-current={item.isActive ? 'page' : undefined}
        >
          {item.name}
        </Link>
      ))}
    </>
  );
};

export { LinkSection };
