import { Disclosure } from '@headlessui/react';
import React from 'react';
import isEqual from 'react-fast-compare';

import { ActionsSection } from './ActionsSection';
import { LinkSection } from './LinkSection';
import { MobileMenuButton } from './MobileMenuButton';
import { MobileMenuNavSection } from './MobileMenuNavSection';
import { MobileMenuProfileSection } from './MobileMenuProfileSection';
import { NavbarLogo } from './NavbarLogo';
import { SearchSection } from './SearchSection';

const TopNavbar = React.memo(
  () => (
    <Disclosure as="nav" className="bg-gray-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="relative h-16 flex items-center justify-between border-b border-gray-200">
              <div className="flex items-center">
                <NavbarLogo />
                <div className="hidden lg:block lg:ml-10">
                  <div className="flex space-x-4">
                    <LinkSection />
                  </div>
                </div>
              </div>
              <div className="flex-1 px-2 flex justify-center lg:ml-6 lg:justify-end" />
              <div className="hidden lg:block lg:ml-4" />
              <div className="flex lg:hidden">
                <MobileMenuButton open={open} />
              </div>
            </div>
          </div>
          <Disclosure.Panel className="bg-gray-50 border-b border-gray-200 lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <MobileMenuNavSection />
            </div>
            <div className="pt-4 pb-3 border-t border-gray-200" />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ),
  isEqual,
);

TopNavbar.displayName = 'TopNavbar';

export { TopNavbar };
