import 'typeface-exo-2';
import '../styles.css';

import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { Footer } from '../Footer';
import { TopNavbar } from '../TopNavbar';

type Props = {
  children: ReactNode;
};

const Layout = ({ children }: Props) => (
  <React.StrictMode>
    <Helmet titleTemplate="Skyint Ltd." defaultTitle="Skyint Ltd.">
      <meta name="description" content="Career opportunity at Skyint Ltd." />
    </Helmet>
    <div className="relative min-h-screen bg-white">
      <TopNavbar />
      <main>{children}</main>
      <Footer />
    </div>
  </React.StrictMode>
);

// eslint-disable-next-line import/no-default-export
export default Layout;
